var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    staticStyle: {
      "flex-direction": "unset",
      "border": "none"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('form', [_c('h5', [_vm._v(" Account details "), _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    ref: "account-name",
    attrs: {
      "id": _vm.controls.account_name.id,
      "value": _vm.data.account_name,
      "label": _vm.controls.account_name.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "keyup": _vm.onAccountNameKeyUp,
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.website.id,
      "value": _vm.data.website,
      "label": _vm.controls.website.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.phone.id,
      "value": _vm.data.phone,
      "label": _vm.controls.phone.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.fax.id,
      "value": _vm.data.fax,
      "label": _vm.controls.fax.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.email.id,
      "value": _vm.data.email,
      "label": _vm.controls.email.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.category.id,
      "value": _vm.data.category,
      "label": _vm.controls.category.label,
      "readonly": _vm.controls.category.readonly,
      "options": _vm.controls.category.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_vm.$permitted(_vm.controls.visible_global.id).visible ? _c('inline-switch', {
    attrs: {
      "id": _vm.controls.visible_global.id,
      "value": _vm.data.visible_global,
      "readonly": false,
      "label": _vm.controls.visible_global.label
    },
    on: {
      "changed": _vm.onVisibleGlobalChange
    }
  }) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created.id,
      "value": _vm.data.created,
      "label": _vm.controls.created.label,
      "readonly": _vm.controls.created.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified.id,
      "value": _vm.data.modified,
      "label": _vm.controls.modified.label,
      "readonly": _vm.controls.modified.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _vm.data.last_activity_date !== '' ? _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": "last_activity_date",
      "value": _vm.data.last_activity_date,
      "label": "Last activity date",
      "readonly": true,
      "mode": _vm.$constants.FORMCONTROLMODE.VIEW
    }
  })], 1) : _vm._e(), _vm.data.last_activity_date !== '' ? _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": "last_activity",
      "value": {
        label: _vm.data.last_activity_subject,
        id: _vm.data.last_activity_id
      },
      "label": "Last activity",
      "readonly": true,
      "mode": _vm.$constants.FORMCONTROLMODE.VIEW,
      "link-mode": true
    },
    on: {
      "link-clicked": _vm.onLastActivityLinkClicked
    }
  })], 1) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "chevron-down"
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-1",
      modifiers: {
        "collapse-1": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Billing address details ")])], 1)], 1), _c('b-collapse', {
    staticClass: "mt-2",
    attrs: {
      "id": "collapse-1"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.street.id,
      "value": _vm.data.street,
      "label": _vm.controls.street.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.country.id,
      "value": _vm.data.country,
      "label": _vm.controls.country.label,
      "readonly": _vm.controls.country.readonly,
      "options": _vm.controls.country.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.state.id,
      "value": _vm.data.state,
      "label": _vm.controls.state.label,
      "readonly": _vm.controls.state.readonly,
      "options": _vm.controls.state.options,
      "allow-empty": false,
      "taggable": _vm.isStateTaggable,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.city.id,
      "value": _vm.data.city,
      "label": _vm.controls.city.label,
      "readonly": _vm.controls.city.readonly,
      "options": _vm.controls.city.options,
      "allow-empty": false,
      "taggable": true,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.postal_code.id,
      "value": _vm.data.postal_code,
      "label": _vm.controls.postal_code.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.pobox.id,
      "value": _vm.data.pobox,
      "label": _vm.controls.pobox.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.latitude.id,
      "value": _vm.data.latitude,
      "label": _vm.controls.latitude.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.longitude.id,
      "value": _vm.data.longitude,
      "label": _vm.controls.longitude.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "chevron-down"
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-2",
      modifiers: {
        "collapse-2": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Distributor information ")])], 1)], 1), _c('b-collapse', {
    staticClass: "mt-2",
    attrs: {
      "id": "collapse-2"
    }
  }, [_c('b-row'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.ship_email.id,
      "value": _vm.data.ship_email,
      "label": _vm.controls.ship_email.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.ship_contact.id,
      "value": _vm.data.ship_contact,
      "label": _vm.controls.ship_contact.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.bill_email.id,
      "value": _vm.data.bill_email,
      "label": _vm.controls.bill_email.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.bill_contact.id,
      "value": _vm.data.bill_contact,
      "label": _vm.controls.bill_contact.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.tax_wh_number.id,
      "value": _vm.data.tax_wh_number,
      "label": _vm.controls.tax_wh_number.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.fein.id,
      "value": _vm.data.fein,
      "label": _vm.controls.fein.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.tax_wh_name.id,
      "value": _vm.data.tax_wh_name,
      "label": _vm.controls.tax_wh_name.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.auth_wh_keeper_number.id,
      "value": _vm.data.auth_wh_keeper_number,
      "label": _vm.controls.auth_wh_keeper_number.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.ship_street.id,
      "value": _vm.data.ship_street,
      "label": _vm.controls.ship_street.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.ship_country.id,
      "value": _vm.data.ship_country,
      "label": _vm.controls.ship_country.label,
      "readonly": _vm.controls.ship_country.readonly,
      "options": _vm.controls.ship_country.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.ship_state.id,
      "value": _vm.data.ship_state,
      "label": _vm.controls.ship_state.label,
      "readonly": _vm.controls.ship_state.readonly,
      "options": _vm.controls.ship_state.options,
      "allow-empty": false,
      "taggable": true,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.ship_city.id,
      "value": _vm.data.ship_city,
      "label": _vm.controls.ship_city.label,
      "readonly": _vm.controls.ship_city.readonly,
      "options": _vm.controls.ship_city.options,
      "allow-empty": false,
      "taggable": true,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.ship_postal_code.id,
      "value": _vm.data.ship_postal_code,
      "label": _vm.controls.ship_postal_code.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.ship_pobox.id,
      "value": _vm.data.ship_pobox,
      "label": _vm.controls.ship_pobox.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "chevron-down"
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-3",
      modifiers: {
        "collapse-3": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Description ")])], 1)], 1), _c('b-collapse', {
    staticClass: "mt-2",
    attrs: {
      "id": "collapse-3"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.description.id,
      "value": _vm.data.description,
      "label": _vm.controls.description.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false,
      "is-text-area": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1)], 1)], 1)]), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.contacts.title
    }
  }, [_c('contacts-table', {
    attrs: {
      "account-id": _vm.id
    },
    on: {
      "loaded": _vm.onContactsLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.activities.title
    }
  }, [_c('activities-table', {
    attrs: {
      "account-id": _vm.id
    },
    on: {
      "loaded": _vm.onActivitiesLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.invoices.title
    }
  }, [_c('invoices-table', {
    attrs: {
      "account-id": _vm.id
    },
    on: {
      "loaded": _vm.onInvoicesLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE && _vm.data.category.label === 'Supplier' ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.orders.title
    }
  }, [_c('orders-table', {
    attrs: {
      "account-id": _vm.id
    },
    on: {
      "loaded": _vm.onOrdersLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.files.title
    }
  }, [_c('files-container', {
    attrs: {
      "module-id": _vm.$constants.MODULES.Accounts.id,
      "entity-id": _vm.id,
      "show-category": true
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.dataHistory.title
    }
  }, [_c('data-history-table', {
    attrs: {
      "module": "accounts",
      "entity-id": _vm.id
    },
    on: {
      "loaded": _vm.onDataHistoryLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE && _vm.isDistributor ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.warehouseOrders.title
    }
  }, [_c('warehouse-orders-table', {
    attrs: {
      "account-id": _vm.id
    },
    on: {
      "loaded": _vm.onWarehouseOrdersLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE && _vm.isDistributor ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.warehousePicklists.title
    }
  }, [_c('warehouse-picklists-table', {
    attrs: {
      "account-id": _vm.id
    },
    on: {
      "loaded": _vm.onWarehousePicklistsLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE && _vm.isDistributor ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.warehouseInvoices.title
    }
  }, [_c('warehouse-invoices-table', {
    attrs: {
      "account-id": _vm.id
    },
    on: {
      "loaded": _vm.onWarehouseInvoicesLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE && _vm.isDistributor ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.markets.title
    }
  }, [_c('markets-table', {
    attrs: {
      "account-id": _vm.id
    },
    on: {
      "loaded": _vm.onMarketsLoad
    }
  })], 1) : _vm._e()], 1), _vm.action !== 'preview' ? _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.pagination.prev,
        next: _vm.pagination.next
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        return _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.pagination.prev.id,
            page: _vm.pagination.prev.page
          }
        });
      },
      "next-item": function nextItem($event) {
        return _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.pagination.next.id,
            page: _vm.pagination.next.page
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Accounts'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Account submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Account submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  }) : _vm._e()], 1)]), _c('b-modal', {
    ref: "activity-preview-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "title": _vm.activityPreviewModal.title
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "80vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('ActivitySubmissionForm', {
    attrs: {
      "id": _vm.activityPreviewModal.id,
      "action": "preview"
    }
  })], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.openActivityInNewWindow(_vm.activityPreviewModal.id);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "share"
    }
  }), _vm._v(" Open in new window ")], 1), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeActivityPreview();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1), _c('b-modal', {
    ref: "vm-modal-form",
    staticClass: "vm-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "sm",
      "title": _vm.marketModal.title
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "market-weight",
      "type": "number"
    },
    model: {
      value: _vm.marketModal.value.weight,
      callback: function callback($$v) {
        _vm.$set(_vm.marketModal.value, "weight", $$v);
      },
      expression: "marketModal.value.weight"
    }
  })], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.saveMarketsModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "save"
    }
  }), _vm._v(" Save ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }