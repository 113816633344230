<template>
  <div class="animated fadeIn">
    <div>
      <b-card>
        <b-tabs content-class="mt-3" justified>
          <b-tab title="Overview" active>
            <b-row>
              <b-col lg="12">
                <b-card>
                  <div class="card-body">
                    <h5 v-if="mode === $constants.FORM_MODE.CREATE">
                      New Project
                    </h5>
                    <h5 v-if="mode !== $constants.FORM_MODE.CREATE">
                      Project overview
                    </h5>
                    <hr />
                    <b-row>
                      <b-col lg="3" md="3">
                        <inline-input
                          :id="controls.project_name.id"
                          :value="data.project_name"
                          :label="controls.project_name.label"
                          :readonly="controls.project_name.readonly"
                          :mode="mode"
                          :required="true"
                          @changed="updateField"
                        />
                      </b-col>
                      <b-col lg="3" md="3">
                        <div class="form-group">
                          <label class="col-form-label">Start Date</label>
                          <input
                            :value="data.start_date"
                            class="form-control"
                            readonly
                          />
                        </div>
                      </b-col>
                      <b-col lg="3" md="3">
                        <div class="form-group">
                          <label class="col-form-label">Finish Date</label>
                          <input
                            :value="data.finish_date"
                            class="form-control"
                            readonly
                          />
                        </div>
                      </b-col>
                      <b-col lg="3" md="3">
                        <inline-select
                          :id="controls.account.id"
                          :width="20"
                          :value="data.account"
                          :label="controls.account.label"
                          :readonly="controls.account.readonly"
                          :options="controls.account.options"
                          :loading="controls.account.loading"
                          :allow-empty="true"
                          :multiple="false"
                          :mode="mode"
                          :link-mode="false"
                          :async="true"
                          @async-search="onAccountSearch"
                          @changed="updateSelect"
                          :required="false"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="12" v-if="!id">
                        <label
                          class="col-form-label"
                          style="cursor: pointer"
                          :for="controls.editor.id"
                          ><strong>Description</strong></label
                        >
                        <ckeditor
                          ref="ckeditor"
                          :id="controls.editor.id"
                          :editor="editor"
                          :disabled="controls.editor.readonly"
                          v-model="data.description"
                          :config="controls.editor.config"
                          @blur="onEditorBlur"
                        />
                      </b-col>
                      <b-col lg="12" v-if="id">
                        <label
                          v-b-toggle.collapse-1
                          class="col-form-label"
                          style="cursor: pointer"
                          :for="controls.editor.id"
                          ><strong>Description</strong></label
                        >

                        <b-collapse id="collapse-1">
                          <ckeditor
                            ref="ckeditor"
                            :id="controls.editor.id"
                            :editor="editor"
                            :disabled="controls.editor.readonly"
                            v-model="data.description"
                            :config="controls.editor.config"
                            @blur="onEditorBlur"
                          />
                        </b-collapse>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row v-if="id">
              <b-col lg="6">
                <b-card style="min-height: 22em">
                  <div class="card-body">
                    <h5>Participants</h5>
                    <hr />
                    <div>
                      <clip-loader
                        class="ml-3 mt-2"
                        :size="14"
                        color="#36D7B7"
                        :loading="data.isMembersLoading"
                      />

                      <b-container fluid>
                        <b-row align-v="start">
                          <b-col
                            v-for="(member, itemObjKey) in data.members"
                            :key="itemObjKey"
                          >
                            <b-card
                              style="
                                width: 8em;
                                height: 10em;
                                text-align: center;
                              "
                              class="border-0"
                            >
                              <div v-if="member.is_group === '0'">
                                <img
                                  :src="
                                    'img/avatars/' +
                                    (member.imagename
                                      ? member.imagename
                                      : 'default.png')
                                  "
                                  class="img-avatar img-participant"
                                />
                              </div>
                              <div v-if="member.is_group === '1'">
                                <img
                                  :src="
                                    'img/avatars/' +
                                    (member.imagename
                                      ? member.imagename
                                      : 'default_group.png')
                                  "
                                  class="img-avatar img-participant"
                                />
                              </div>
                              <div>{{ member.name }}</div>
                              <div>
                                <b-progress
                                  :max="100"
                                  height="1rem"
                                  :striped="true"
                                  variant="primary"
                                >
                                  <b-progress-bar
                                    :value="parseFloat(member.progress)"
                                    :label="`${parseFloat(
                                      member.progress
                                    ).toFixed(2)}%`"
                                  />
                                </b-progress>
                              </div>
                            </b-card>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col lg="6">
                <b-card style="min-height: 22em">
                  <div class="card-body">
                    <h5>Summary</h5>
                    <hr />
                    <b-row>
                      <b-col>
                        <h6 v-if="data.tasks_total">
                          {{ data.tasks_completed }} /
                          {{ data.tasks_total }} OPENED TASKS
                        </h6>
                        <hr />

                        <b-card class="border-0 d-flex align-items-center">
                          <Progress
                            :transition-duration="500"
                            :radius="50"
                            :stroke-width="15"
                            :value="data.tasks_percent"
                            stroke-color="#AAFF00"
                          />
                        </b-card>
                      </b-col>
                      <b-col>
                        <h6 v-if="data.days_left && data.days_left >= 0">
                          {{ data.days_left }} / {{ data.days_total }} DAYS LEFT
                        </h6>
                        <h6 v-if="data.days_left && data.days_left < 0">
                          <b-badge variant="danger">
                            {{ Math.abs(data.days_left) }} /
                            {{ data.days_total }} DAYS OVERDUE
                          </b-badge>
                        </h6>
                        <hr />

                        <b-card class="border-0 d-flex align-items-center">
                          <Progress
                            :transition-duration="500"
                            :radius="50"
                            :stroke-color="
                              data.days_left < 0 ? 'red' : '#AAFF00'
                            "
                            :stroke-width="15"
                            :value="data.days_percent"
                          />
                        </b-card>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row v-if="action !== 'preview' && id">
              <b-col>
                <b-card>
                  <div class="card-body">
                    <h5>Project tasks</h5>
                  </div>
                  <b-row>
                    <b-col lg="4">
                      <b-button-group>
                        <button
                          class="btn btn-outline-dark btn"
                          type="button"
                          @click="showModalTaskList()"
                          title="Include tasks"
                        >
                          <font-awesome-icon icon="plus" />
                        </button>
                        <button
                          class="btn btn-outline-dark btn"
                          type="button"
                          @click="excludeTasks()"
                          :disabled="projectTasks.selected.length === 0"
                          title="Exclude tasks"
                        >
                          <font-awesome-icon icon="minus" />
                        </button>
                      </b-button-group>
                    </b-col>
                  </b-row>
                  <hr />
                  <!--<project-tasks ref='project-tasks' :projectId="id" @record-selected="onExistingTaskSelected" :rowsPerPage="10"> </project-tasks>-->

                  <tasks-table
                    ref="project-tasks"
                    :project-id="id"
                    :is-nested-table="true"
                    :filter-by-column="true"
                    :selectable-rows="true"
                    @row-select="onExistingTaskSelected"
                    @loaded="onTasksTableLoad"
                    @bulk-complete="onBulkTasksCompleted"
                    :rows-per-page="10"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            :title="controls.tabs.files.title"
            v-if="mode !== $constants.FORM_MODE.CREATE"
          >
            <files-container
              :module-id="$route.meta.module.id"
              :entity-id="id"
              @loaded="onFileContainerLoad"
            />
          </b-tab>

          <b-tab title="Gantt" v-if="mode !== $constants.FORM_MODE.CREATE">
            <gantt ref="gantt" :project-id="this.id" @loaded="onGanttLoad" />
          </b-tab>

          <b-tab
            :title="controls.tabs.dataHistory.title"
            v-if="mode !== $constants.FORM_MODE.CREATE"
          >
            <data-history-table
              ref="dataHistory"
              module="projects"
              :entity-id="id"
              @loaded="onDataHistoryLoad"
            />
          </b-tab>
        </b-tabs>
        <hr />

        <form-submission-actions
          v-if="action !== 'preview'"
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey($route.meta.module, id),
            next: $customTable.getNextKey($route.meta.module, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey($route.meta.module, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey($route.meta.module, id)
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Projects' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Project submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Project submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </b-card>
    </div>
    <b-modal
      ref="modal-tasklist"
      centered
      hide-footer
      size="xl"
      title="Select tasks"
    >
      <tasks-table
        filter="available"
        :project-id="id"
        :rows-per-page="5"
        :selectable-rows="true"
        :is-insert-allowed="false"
        :filter-by-column="true"
        @row-select="onRecordSelected"
      />
      <hr />
      <div class="form-row d-flex justify-content-end">
        <b-button
          variant="outline-dark"
          class="m-1"
          :disabled="modalTaskList.selected.length === 0"
          @click="includeTasks()"
        >
          <font-awesome-icon icon="plus" /> Add
        </b-button>
        <b-button variant="outline-dark" class="m-1" @click="closeModal()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'

import { ClipLoader } from '@saeris/vue-spinners'

import Progress from 'easy-circular-progress'
import FormSubmissionActions from '@/components/FormSubmissionActions'

import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelectMultiple'
import CKEditor from '@ckeditor/ckeditor5-vue'
import InlineEditor from '@ckeditor/ckeditor5-build-inline'

Vue.use(CKEditor)

import TasksTable from '@/views/ProjectManagement/TasksTable.vue'

import FilesContainer from '@/components/FilesContainer'

import { mapState, mapActions } from 'vuex'

import Gantt from '@/views/ProjectManagement/Gantt'

import DataHistoryTable from '@/components/DataHistoryTable'

export default {
  name: 'ProjectSubmissionForm',
  props: {
    id: {
      type: [String, Number],
      default: undefined
    },
    action: {
      type: String,
      default: undefined
    }
  },
  components: {
    InlineInput,
    InlineSelect,

    ckeditor: CKEditor.component,
    ClipLoader,
    TasksTable,
    Progress,
    FilesContainer,
    FormSubmissionActions,
    Gantt,
    DataHistoryTable
  },
  data: function () {
    return {
      mode: undefined,
      editor: InlineEditor,
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        tabs: {
          files: {
            title: 'Files'
          },
          dataHistory: {
            title: 'Data History'
          }
        },
        buttons: {
          edit: {
            id: 'button:details_edit'
          },
          save: {
            id: 'button:details_save'
          }
        },
        project_name: {
          id: 'input:project_name',
          label: 'Project Name',
          changed: false,
          readonly: false
        },
        description: {
          id: 'input:description',
          label: 'Description',
          changed: false,
          readonly: false
        },
        account: {
          id: 'select:account',
          label: 'Account',
          options: [],
          changed: false,
          readonly: false,
          loading: false
        },
        editor: {
          data: '',
          config: {
            startupFocus: true,
            startupShowBorders: true
            // readOnly: true,
          },
          readonly: false
        }
      },
      data: {
        id: '',
        project_name: '',
        description: '',
        start_date: '',
        finish_date: '',
        isMembersLoading: false,
        members: [],
        days_percent: 0,
        tasks_percent: 0,
        account: ''
      },
      projectTasks: {
        selected: []
      },
      modalTaskList: {
        selected: []
      }
    }
  },

  computed: mapState({
    profile: state => state.profile
  }),
  created () {
    this.initialize()
  },
  mounted: function () {
    //this.$refs.line.animate(1.0)
  },
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    onFileContainerLoad (count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`
    },

    initialize: async function () {
      if (this.action === 'create') {
        this.mode = this.$constants.FORM_MODE.CREATE
      }
      if (this.action === 'edit') {
        this.mode = this.$constants.FORM_MODE.EDIT
      }
      if (this.action === 'view') {
        this.mode = this.$constants.FORM_MODE.VIEW
      }

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.fetchData(this.id)
      }
    },
    onAccountSearch (payload) {
      let self = this

      self.controls.account.loading = true

      this.$api
        .post('dictionaries/accounts', {
          query: payload.query,
          starts_with: payload.startsWith
        })
        .then(response => {
          self.controls.account.loading = false

          self.controls.account.options = response.map(u => ({
            id: u.id,
            label: u.name
          }))
        })
    },
    save (_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')

        return Promise.resolve(false)
      }

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        return this.createProject(_mode)
      } else {
        return this.updateProject(_mode)
      }
    },
    cancel () {
      this.$router.back()
    },
    createProject (_mode) {
      let self = this

      this.saveInProgress = _mode === 'tabular'
      this.saveAndViewInProgress = _mode === 'view'

      return this.$api['put']('projects', this.data)
        .then(response => {
          self.saveInProgress = false
          self.saveAndViewInProgress = false

          self.$form.makeToastInfo(response.message)

          this.$router.currentRoute.params.id = response.id

          return response.id
        })
        .catch(error => {
          console.log(error)

          self.$form.makeToastError(error.message)

          return Promise.resolve(false)
        })
    },
    updateProject (_mode) {
      let self = this

      this.saveInProgress = _mode === 'tabular'
      this.saveAndViewInProgress = _mode === 'view'

      return this.$api['put'](`projects/${this.data.id}`, this.data)
        .then(response => {
          self.$form.makeToastInfo(response.message)

          self.saveInProgress = false
          self.saveAndViewInProgress = false

          return self.data.id
        })
        .catch(error => {
          console.log(error)

          self.$form.makeToastError(error.message)

          return Promise.resolve(false)
        })
    },
    onEditorBlur () {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return

      this.updateField('description', this.data.description, this.mode)
    },
    fetchData: async function (id) {
      let self = this

      //self.$refs['project-tasks'].refresh()
      return this.$api
        .get(`projects/${id}`)
        .then(async function (response) {
          if (self.$_.isEmpty(response)) return

          self.data.id = self.id

          self.data.project_name = response['Project Name']
          self.data.description = response['Description']
          self.data.start_date = response['Start Date']
          self.data.finish_date = response['Finish Date']
          self.data.progress = response['Progress']
          self.data.tasks_completed = response['tasks_completed']
          self.data.tasks_total = response['tasks_total']
          self.data.days_left = response['days_left']
          self.data.days_total = response['days_total']
          self.data.account = {
            id: response['Account ID'],
            label: response['Account Name']
          }

          if (self.data.days_left > 0)
            self.data.days_percent = self.data.days_total
              ? parseFloat(
                  (
                    (Math.abs(self.data.days_total - self.data.days_left) *
                      100) /
                    self.data.days_total
                  ).toFixed(2)
                )
              : 0
          else self.data.days_percent = 100

          self.data.tasks_percent = self.data.tasks_total
            ? parseFloat(
                (
                  (self.data.tasks_completed * 100) /
                  self.data.tasks_total
                ).toFixed(2)
              )
            : 0

          self.data.isMembersLoading = true

          console.log('self.id', self.id)
          self.data.members = await self.$api.get(`projects/${self.id}/members`)
          console.log('members:', self.data.members)
          self.data.isMembersLoading = false
        })
        .catch(error => {
          console.log(error)

          self.$form.makeToastError(error.message)
        })
    },

    updateField (field, value) {
      let self = this
      let params = {}
      params['id'] = this.id
      params[field] = value

      //update local data values - it can be used in Edit action
      self.data[field] = value

      //if control in View mode then update database
      if (this.mode === this.$constants.FORMCONTROLMODE.VIEW)
        this.$api
          .put('projects/${id}', params)
          .then(response => {
            self.$form.makeToastInfo(`${field}: ${response.message}`)

            self.controls[field].changed = false

            self.initialize()
          })
          .catch(response => {
            console.log(response)

            self.$form.makeToastError(`${field}: ${response.message}`)
          })
    },
    updateSelect (id, value) {
      this.updateField(id, value)
    },
    showModalTaskList () {
      this.$refs['modal-tasklist'].show()
    },
    onExistingTaskSelected (payload) {
      if (!payload.row) return
      let row = payload.row
      if (this.projectTasks.selected.find(i => i.ID === row['ID'])) {
        this.projectTasks.selected = this.projectTasks.selected.filter(
          i => i.ID !== row['ID']
        )
      } else {
        this.projectTasks.selected.push(row)
      }
    },
    onRecordSelected (payload) {
      let row = payload.row
      if (this.modalTaskList.selected.find(i => i.ID === row['ID'])) {
        this.modalTaskList.selected = this.modalTaskList.selected.filter(
          i => i.ID !== row['ID']
        )
      } else {
        this.modalTaskList.selected.push(row)
      }
    },
    includeTasks: async function () {
      let self = this
      this.$refs['modal-tasklist'].hide()

      //this.$refs['project-tasks'].dataTable.dataSet.push(this.modalTaskList.selected)

      let data = this.modalTaskList.selected.map(t => ({
        task_id: t.ID,
        project_id: this.id
      }))

      self.modalTaskList.selected = []

      this.$api
        .put(`projects/${this.id}/tasks/include`, data)
        .then(() => {
          self.fetchData(self.id)

          self.$refs['project-tasks'].drawDataTable()
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(`Error: ${response.message}`)

          self.fetchData(self.id)

          self.$refs['project-tasks'].drawDataTable()
        })
    },
    onBulkTasksCompleted () {
      this.projectTasks.selected = []
    },
    excludeTasks: async function () {
      if (
        this.projectTasks.selected.length ===
        this.$refs['project-tasks'].dataTable.dataSet.length
      ) {
        this.$form.msgBoxOk(
          'Project must have at least one task. Please change selection'
        )
        return
      }

      let confirm = await this.$form.showConfirmation(
        'Selected tasks will be excluded from project. Do you want to proceed?'
      )

      if (!confirm) return

      let self = this
      let data = this.projectTasks.selected.map(t => ({
        task_id: t.ID,
        project_id: this.id
      }))

      this.$api
        .put(`projects/${this.id}/tasks/exclude`, data)
        .then(() => {
          self.$refs['project-tasks'].drawDataTable()

          self.fetchData(self.id)

          this.projectTasks.selected = []
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(`Error: ${response.message}`)

          self.$refs['project-tasks'].drawDataTable()

          self.fetchData(self.id)

          this.projectTasks.selected = []

          //this.$refs['project-tasks'].drawDataTable()
        })
    },
    closeModal: function () {
      this.$refs['modal-tasklist'].hide()
    },
    onGanttLoad () {
      let rawData = this.$refs['gantt'].rawData

      console.log(
        'onGanttLoad:',
        this.id,
        rawData,
        rawData.filter(t => t['Project ID'])
      )

      this.$refs['gantt'].dataTable.dataSet = rawData.filter(
        t => t['Project ID'] === this.id
      )
    },
    onDataHistoryLoad (count) {
      if (count > 0)
        this.controls.tabs.dataHistory.title = `Data History (${count})`
    },
    onTasksTableLoad () {
      this.$refs.dataHistory.getData()
    }
  },
  watch: {}
}
</script>

<style scoped>
.vue-daterange-picker {
  display: block !important;
}

.vue__time-picker input.display-time {
  border: 0px solid #d2d2d2;
  width: 7em;
  height: 2.2em;
  margin: -0.3em -0.5em;
}

.ck.ck-editor__editable_inline {
  border: 1px solid #e8e8e8;
  min-height: 100px !important;
}

.ck.ck-editor__editable_inline.ck-focused {
  background-color: #ffffed;
}

.btn-outline-dark {
  border-color: #ccc;
}

.daily-content {
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}

.img-participant {
  height: 4em;
  padding: 5px;
}

.form-control[readonly] {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

/*contact preview modal resize */
.modal-lg {
  max-width: 70vw !important;
  max-height: 70vh !important;
}

/***********************/
</style>
