var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_vm._v(" projectId: " + _vm._s(_vm.projectId) + " "), _c('div', {
    staticClass: "card-body"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "opacity": 0.5,
      "spinner-variant": "secondary",
      "rounded": "sm"
    }
  }, [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('form', [_vm.mode === _vm.FORM_MODE.CREATE ? _c('h5', [_vm._v("New Task")]) : _vm._e(), _vm.mode !== _vm.FORM_MODE.CREATE ? _c('h5', [_vm._v("Task #" + _vm._s(_vm.data.id))]) : _vm._e(), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4",
      "md": "4"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.task_name.id,
      "value": _vm.data.task_name,
      "label": _vm.controls.task_name.label,
      "readonly": _vm.controls.task_name.readonly,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "2"
    }
  }, [_c('inline-switch', {
    attrs: {
      "id": _vm.controls.activity_reminder.id,
      "value": _vm.data.activity_reminder,
      "readonly": false,
      "label": _vm.controls.activity_reminder.label
    },
    on: {
      "changed": _vm.onActivityReminderChange
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "2"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.is_private.id,
      "value": _vm.data.is_private,
      "label": _vm.controls.is_private.label,
      "readonly": _vm.controls.is_private.readonly,
      "options": _vm.controls.is_private.options,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.recurring_type.id,
      "value": _vm.data.recurring_type,
      "label": _vm.controls.recurring_type.label,
      "readonly": _vm.data.recurring_type.readonly,
      "options": _vm.controls.recurring_type.options,
      "allow-empty": true,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.account.id,
      "value": _vm.data.account,
      "label": _vm.controls.account.label,
      "readonly": _vm.controls.account.readonly,
      "options": _vm.controls.account.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": _vm.data.activity_reminder
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.date_start.id,
      "value-single": _vm.data.date_start,
      "label": _vm.controls.date_start.label,
      "readonly": _vm.controls.date_start.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.due_date.id,
      "value-single": _vm.data.due_date,
      "label": _vm.controls.due_date.label,
      "readonly": _vm.controls.due_date.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.status.id,
      "value": _vm.data.status,
      "label": _vm.controls.status.label,
      "readonly": _vm.data.activity_reminder,
      "options": _vm.controls.status.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "2",
      "md": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.priority.id,
      "value": _vm.data.priority,
      "label": _vm.controls.priority.label,
      "readonly": _vm.controls.priority.readonly,
      "options": _vm.controls.priority.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.assigned_to.id,
      "value": _vm.data.assigned_to,
      "label": _vm.controls.assigned_to.label,
      "group_values": 'group_values',
      "group_label": 'group_label',
      "group-select": true,
      "readonly": _vm.controls.assigned_to.readonly,
      "options": _vm.controls.assigned_to.options,
      "allow-empty": true,
      "multiple": true,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.project.id,
      "value": _vm.data.project,
      "label": _vm.controls.project.label,
      "readonly": _vm.controls.project.readonly,
      "options": _vm.controls.project.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": false,
      "link-mode": true
    },
    on: {
      "changed": _vm.updateSelect,
      "link-clicked": _vm.onProjectLinkClicked
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "6"
    }
  }, [_c('inline-switch', {
    attrs: {
      "id": _vm.controls.sendnotification.id,
      "value": _vm.data.sendnotification,
      "readonly": false,
      "label": "Remind on ".concat(_vm.data.recurring_type.id ? 'Start Date' : 'Due Date')
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('label', {
    staticClass: "col-form-label",
    attrs: {
      "for": _vm.controls.editor.id
    }
  }, [_vm._v("Description")]), _c('ckeditor', {
    ref: "ckeditor",
    attrs: {
      "id": _vm.controls.editor.id,
      "editor": _vm.editor,
      "disabled": _vm.controls.editor.readonly,
      "config": _vm.controls.editor.config
    },
    on: {
      "blur": _vm.onEditorBlur
    },
    model: {
      value: _vm.data.description,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "description", $$v);
      },
      expression: "data.description"
    }
  })], 1)], 1)], 1)]), _vm.mode !== _vm.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.files.title
    }
  }, [_c('files-container', {
    attrs: {
      "module-id": _vm.$route.meta.module.id,
      "entity-id": _vm.id
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.dataHistory.title
    }
  }, [_c('data-history-table', {
    attrs: {
      "module": "tasks",
      "entity-id": _vm.id
    },
    on: {
      "loaded": _vm.onDataHistoryLoad
    }
  })], 1) : _vm._e()], 1)], 1), _c('hr'), !_vm.createTaskAction ? _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      },
      "custom-buttons": _vm.customButtons
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('back').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Tasks'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Task submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Task submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      },
      "custom-click": function customClick(name) {
        _this[name]();
      }
    }
  }) : _vm._e(), _vm.createTaskAction && _vm.action !== 'preview' ? _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "hide-regular-buttons": true,
      "custom-buttons": [{
        text: 'Save',
        icon: 'save',
        loading: _vm.saveAndViewInProgress,
        visible: true,
        method: 'saveTaskModal',
        variant: 'outline-dark'
      }, {
        text: 'Close',
        icon: 'times',
        loading: false,
        visible: true,
        method: 'closeTaskModal',
        variant: 'outline-dark'
      }]
    },
    on: {
      "custom-click": function customClick(name) {
        _this[name]();
      }
    }
  }) : _vm._e()], 1)]), _c('project-preview-modal', {
    ref: "project-preview-modal"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }